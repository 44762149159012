var Leadbox = function() {
  var t, e, i = {}, o = {
      scriptTag: null,
      widgetId: null,
      dataUrl: null,
      widgetUrl: null
  };
  i.init = function() {
      o.scriptTag = i.getScriptTag();
      if (null !== o.scriptTag) {
          o.widgetId = i.getWidgetId();
          o.dataUrl = i.getDataUrl();
          o.widgetUrl = i.getWidgetUrl();
          o.isMultiProfile = o.scriptTag.hasAttribute("data-multi-profile") ? "isMultiProfile" : "isSingleProfile";
          i.injectStylesToDOM();
          i.render();
          i.attachListeners()
      }
  }
  ;
  i.getScriptTag = function() {
      var t = document.querySelectorAll("[data-leadferno]");
      return (t = t.length > 0 ? t : document.querySelectorAll("[data-bid][data-url]")).length > 0 ? t.item(0) : null
  }
  ;
  i.injectStylesToDOM = function() {
      document.head.insertAdjacentHTML("beforeend", "<style>html:not(.is-show-leadbox) #leadbox-iframe-container { display: none; } html.is-show-leadbox-highlight.is-hide-leadbox-text-with-us:not(.has-leadbox-open) #leadbox-iframe-container { height: 116px !important; } html.is-hide-leadbox-text-with-us:not(.has-leadbox-open) #leadbox-iframe-container { height: 108px !important; } html.is-hide-leadbox-text-with-us.is-icon-only:not(.has-leadbox-open) #leadbox-iframe-container { width: 110px !important; } html.is-leadbox-mobile.has-leadbox-open #leadbox-iframe-container { width: 100% !important; height: 100% !important; } html.has-leadbox-open #leadbox-iframe-container { width: 426px !important; height: 760px !important; } #leadbox-iframe-container { z-index: 9999 !important; position: fixed !important; bottom: 0 !important; right: 0 !important; width: 308px !important; height: 160px !important; } html.is-show-leadbox-highlight:not(.has-leadbox-open) #leadbox-iframe-container { height: 198px !important; } html.is-leadbox-left-aligned #leadbox-iframe-container { right: initial !important; left: 0 !important; } html.is-leadbox-left-aligned #leadbox-iframe { right: initial !important; left: 0 !important; } iframe#leadbox-iframe { height: 100% !important; width: 100% !important; background: transparent !important; position: absolute !important; bottom: 0 !important; right: 0 !important; border: 0 none !important; display: initial !important; margin: 0 !important; left: initial !important; top: initial !important; }</style>")
  }
  ;
  i.render = function() {
      (t = document.createElement("iframe")).id = "leadbox-iframe";
      t.title = "Leadbox";
      t.src = "".concat(o.widgetUrl, "/index.html#").concat(o.widgetId, ";").concat(o.dataUrl, ";").concat(o.isMultiProfile);
      (e = document.createElement("div")).id = "leadbox-iframe-container";
      e.appendChild(t);
      document.querySelector("body").appendChild(e)
  }
  ;
  i.getWidgetId = function() {
      return o.scriptTag.getAttribute("data-bid")
  }
  ;
  i.getDataUrl = function() {
      return o.scriptTag.getAttribute("data-url")
  }
  ;
  i.getWidgetUrl = function() {
      return "https://widget.leadferno.com"
  }
  ;
  i.attachListeners = function() {
      window.addEventListener("message", (function(e) {
          var o = e.data ? e.data : "";
          if (o.hasOwnProperty("key") && o.hasOwnProperty("data"))
              switch (o.key) {
              case "toggleHtmlClass":
                  document.querySelector("html").classList.toggle(o.data.class, o.data.state);
                  break;
              case "clickToCall":
                  window.open("tel:" + o.data, "_self");
                  break;
              case "ga4-measurement-id":
                  let e = "_ga_" + o.data.replace("G-", "")
                    , n = i.getCookie(e);
                  if (n) {
                      let e = n.split(".")[2] ?? !1;
                      e && !isNaN(e) && t.contentWindow.postMessage({
                          ga4Session: e
                      }, "*")
                  }
              }
          else
              switch (o) {
              case "openForm":
                  document.querySelector("html").classList.add("has-leadbox-open");
                  break;
              case "closeForm":
                  document.querySelector("html").classList.remove("has-leadbox-open");
                  break;
              case "isLeftAligned":
                  document.querySelector("html").classList.add("is-leadbox-left-aligned");
                  break;
              case "initialized":
                  i.postAnalyticsSettings();
                  i.handleWindowResize(!0);
                  document.querySelector("html").classList.add("is-show-leadbox");
                  break;
              case "isHideTextWithUsOnLoad":
                  i.setSessionCookie("lf-is-hide-text-with-us", !0);
                  document.querySelector("html").classList.add("is-hide-leadbox-text-with-us");
                  break;
              case "isIconOnly":
                  document.querySelector("html").classList.add("is-icon-only");
                  break;
              case "isShowHighlight":
                  document.querySelector("html").classList.add("is-show-leadbox-highlight")
              }
      }
      ), !1);
      window.addEventListener("resize", ()=>{
          i.handleWindowResize(!1)
      }
      )
  }
  ;
  i.handleWindowResize = function(e=!1) {
      var o = "desktop";
      if (window.innerWidth < 600) {
          document.querySelector("html").classList.add("is-leadbox-mobile");
          o = "mobile"
      } else
          document.querySelector("html").classList.remove("is-leadbox-mobile");
      var n = window.innerWidth < 375 || window.innerHeight <= 708 && "mobile" !== o;
      t.contentWindow.postMessage({
          isFirstLoad: e,
          display: o,
          isHideLogo: n,
          isHideTextWithUsOnLoad: i.getCookie("lf-is-hide-text-with-us")
      }, "*")
  }
  ;
  i.postAnalyticsSettings = function() {
      let e = o.scriptTag.hasAttribute("data-prevent-tracking");
      t.contentWindow.postMessage({
          path: document.location.pathname,
          href: document.location.href,
          hostname: document.location.hostname,
          cid: i.getCookie("_ga"),
          uid: i.getLeadfernoSessionId(),
          endpoint: e ? null : i.isProd() ? "qq0t1m8n6k" : "8gtcqmtoti",
          isLoggingEnabled: !i.isProd()
      }, "*")
  }
  ;
  i.sendTestEvents = function() {
      i.isProd() || t.contentWindow.postMessage("sendTestEvents", "*")
  }
  ;
  i.getLeadfernoSessionId = function() {
      let t = i.getCookie("leadferno-session") ?? (+new Date).toString(36) + Math.random().toString(36).slice(-5);
      i.setSessionCookie("leadferno-session", t);
      return t
  }
  ;
  i.setSessionCookie = function(t, e) {
      document.cookie = t + "=" + (e || "") + "; path=/";
      return e
  }
  ;
  i.getCookie = function(t) {
      var e = t + "=";
      if (document.cookie && document.cookie.length > 0)
          for (var i = document.cookie.split(";"), o = 0; o < i.length; o++) {
              for (var n = i[o]; " " == n.charAt(0); )
                  n = n.substring(1, n.length);
              if (0 == n.indexOf(e))
                  return n.substring(e.length, n.length)
          }
      return null
  }
  ;
  i.isProd = function() {
      return /^https\:\/\/api\.leadferno\.com/.test(o.dataUrl)
  }
  ;
  i.open = function(e) {
      e ? t.contentWindow.postMessage({
          open: e
      }, "*") : t.contentWindow.postMessage({
          api: "open-leadbox"
      }, "*")
  }
  ;
  i.close = function() {
      t.contentWindow.postMessage({
          api: "close-leadbox"
      }, "*")
  }
  ;
  i.toggle = function() {
      t.contentWindow.postMessage({
          api: "toggle-leadbox"
      }, "*")
  }
  ;
  document.addEventListener("click", (function(t) {
      if (t.target.classList.contains("leadbox:toggle") || t.target.closest(".leadbox\\:toggle")) {
          t.preventDefault();
          i.toggle()
      }
  }
  ));
  return i
}();
window.onload = function() {
  Leadbox.init()
}
;
